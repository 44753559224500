import React, { useEffect, useState } from 'react';

const Home = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    const [activeSection, setActiveSection] = useState('');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        // Check scroll position on load
        handleScroll();

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleToggle = () => {
        setIsMobileNavOpen((prev) => !prev);
    };

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('section');
            let currentSection = '';

            sections.forEach(section => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;
                if (window.scrollY >= sectionTop - 100 && window.scrollY < sectionTop + sectionHeight) {
                    currentSection = section.getAttribute('id');
                }
            });

            setActiveSection(currentSection);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div>
            {/* ======= Header ======= */}
            <header id="header" className={`fixed-top ${isScrolled ? 'header-scrolled' : ''}`}>
                <div className="container d-flex align-items-center justify-content-lg-between">

                    {/* <h1 className="logo me-auto me-lg-0"><a href="index.html">Gp<span>.</span></a></h1> */}
                    {/* Uncomment below if you prefer to use an image logo */}
                    <a href="index.html" className="logo me-auto me-lg-0"><img src="assets/img/layer1-9.png" alt="" className="img-fluid" width="250" /></a>

                    <nav id="navbar" className={`navbar order-last order-lg-0 ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
                        <ul>
                            <li><a className={`nav-link scrollto ${activeSection === 'hero' ? 'active' : ''}`} href="#hero" onClick={() => { setIsMobileNavOpen(false) }}>Home</a></li>
                            {/* <li><a className="nav-link scrollto" href="#about">Concept</a></li> */}
                            <li><a className={`nav-link scrollto ${activeSection === 'partners' ? 'active' : ''}`} href="#partners" onClick={() => { setIsMobileNavOpen(false) }}>Partners</a></li>
                            <li><a className={`nav-link scrollto ${activeSection === 'services' ? 'active' : ''}`} href="#services" onClick={() => { setIsMobileNavOpen(false) }}>Solution</a></li>
                            <li><a className={`nav-link scrollto ${activeSection === 'products' ? 'active' : ''}`} href="#products" onClick={() => { setIsMobileNavOpen(false) }}>Products</a></li>
                            {/* <li className="dropdown"><a href="#"><span>Company</span> <i className="bi bi-chevron-down"></i></a>
                                <ul>
                                    <li><a href="#">Company</a></li>
                                    <li><a href="#">Team</a></li>
                                    <li><a href="#">Drop Down 3</a></li>
                                    <li><a href="#">Drop Down 4</a></li>
                                </ul>
                            </li> */}
                            <li><a className={`nav-link scrollto ${activeSection === 'contact' ? 'active' : ''}`} href="#contact" onClick={() => { setIsMobileNavOpen(false) }}>Contact</a></li>
                        </ul>
                        <i className={`bi mobile-nav-toggle ${isMobileNavOpen ? 'bi-x' : 'bi-list'}`} onClick={handleToggle}></i>
                    </nav>{/* .navbar */}

                    {/* <a href="#about" className="get-started-btn scrollto">Login</a> */}

                </div>
            </header>{/* End Header */}

            {/* ======= Hero Section ======= */}
            <section id="hero" className="d-flex align-items-center justify-content-center">
                <div className="container" data-aos="fade-up">

                    <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
                        <div className="col-lg-8">
                            {/* <h1>OHOINDIA provides subscription-based wellness and specialty care 
                                services through seamless technology platform.<span></span></h1> */}
                            {/* <h2>We are the Digital Consulting and Technology facilitator</h2> */}
                            <h1><span>OHOINDIA</span></h1>
                            {/* <h2>Your Health Care Partner</h2> */}
                            <h3 className='mt-3'>OHOINDIA provides subscription-based wellness and specialty care
                                services through seamless technology platform.</h3>
                        </div>
                    </div>

                    <div className="row gy-4 mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay="250">
                        <div className="col-xl-2 col-md-4">
                            <div className="icon-box">
                                <i className="ri-hospital-fill"></i>
                                <h3><a>Hospitals</a></h3>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4">
                            <div className="icon-box">
                                <i className="ri-empathize-fill"></i>
                                <h3><a>Healthcare Providers</a></h3>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4">
                            <div className="icon-box">
                                <i className="ri-stethoscope-fill"></i>
                                <h3><a>Doctors</a></h3>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4">
                            <div className="icon-box">
                                <i className="ri-mental-health-fill"></i>
                                <h3><a>Patients</a></h3>
                            </div>
                        </div>
                        {/* <div className="col-xl-2 col-md-4">
                            <div className="icon-box">
                                <i className="ri-first-aid-kit-fill"></i>
                                <h3><a>Emergency Care Providers</a></h3>
                            </div>
                        </div> */}
                    </div>

                </div>
            </section>{/* End Hero */}

            <main id="main">

                {/* ======= About Section ======= */}
                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">

                        <div className="row">
                            <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                                <img src="assets/img/ohoindialife-about1.jpg" className="img-fluid" alt="ohoevent_1"
                                    style={{ height: `${windowWidth <= 700 ? '300px' : '430px'}`, width: '700px' }} />
                            </div>
                            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                                {/* <h3>Elevate Healthcare Delivery in India by Unlocking Efficiency and Quality of Care</h3> */}
                                <h3>Elevate Healthcare Delivery in India</h3>
                                <p>
                                    OHOINDIA Technology Solution empowers doctors, hospitals, patients and healthcare providers with simple
                                    workflows to connect,
                                    collaborate and support in a better way. Our unified technology platform helps to improve healthcare
                                    operational insights by offering seamless patient experience by reducing hospitals' administrative
                                    burden.Simplified routine tasks for higher patient satisfaction and care transparency.
                                </p>
                                <h4 className='fs-5 fw-bolder'>Our Expertise</h4>
                                <ul>
                                    {/* <li><i className="ri-check-double-line"></i> Patient acquisition at Zero cost. </li> */}
                                    <li><i className="ri-check-double-line"></i> We provide qualified medical care where otherwise unreachable.</li>
                                    <li><i className="ri-check-double-line"></i> Reduction in patient transportation cost and time taken.</li>
                                    <li><i className="ri-check-double-line"></i> Efficient patient relationship management process.</li>
                                    <li><i className="ri-check-double-line"></i> Ensuring Expertise in patient care.</li>
                                    <li><i className="ri-check-double-line"></i> Offering the virtual family doctor and virtual family hospital.</li>
                                    <li><i className="ri-check-double-line"></i> Technology based services ensures delivering quality services effectively.</li>
                                </ul>

                            </div>
                        </div>

                    </div>
                </section>{/* End About Section */}

                {/* ======= Clients Section ======= */}
                <section id="partners" className="partners mb-3">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Partners</h2>
                            <p>List of Hospitals we are Partnered With</p>
                        </div>

                        <div>
                            <div className="container">
                                <section className="logo-area slider p-0">
                                    <div className="slide"><img src="assets/img/clients/tulasi.png" alt="Logo 1" /></div>
                                    <div className="slide"><img src="assets/img/clients/nakshatra.png" alt="Logo 2" /></div>
                                    <div className="slide"><img src="assets/img/clients/Spectra-Logo.png" alt="Logo 3" /></div>
                                    <div className="slide"><img src="assets/img/clients/MetroCare-logo.png" alt="Logo 4" /></div>
                                    <div className="slide"><img style={{ height: '100px', width: '80px' }} src="assets/img/clients/anupama-logo.png" alt="Logo 5" /></div>
                                    <div className="slide"><img src="assets/img/clients/sri-sri-holistic-logo.jpg" alt="Logo 1" /></div>
                                    <div className="slide"><img src="assets/img/clients/parindevi-logo.png" alt="Logo 2" /></div>
                                    <div className="slide"><img src="assets/img/clients/KK hospital.png" alt="Logo 3" /></div>
                                    <div className="slide"><img style={{ height: '100px', width: '80px' }} src="assets/img/clients/surgeons tree logo.jpg" alt="Logo 4" /></div>
                                    <div className="slide"><img src="assets/img/clients/viva hospital-logo.png" alt="Logo 3" /></div>
                                    <div className="slide"><img src="assets/img/clients/sunrise-logo.png" alt="Logo 4" /></div>
                                    <div className="slide"><img src="assets/img/clients/UDBAVA-logo.png" alt="Logo 5" /></div>
                                    <div className="slide"><img style={{ height: '100px', width: '80px' }} src="assets/img/clients/LIMS Logo.png" alt="Logo 4" /></div>
                                    <div className="slide"><img src="assets/img/clients/Srikara-Hospital-Logo.png" alt="Logo 3" /></div>
                                    <div className="slide"><img src="assets/img/clients/Suraksha-logo.png" alt="Logo 4" /></div>
                                    <div className="slide"><img className="TX-Hospital" src="assets/img/clients/TX-Hospital-Logo.png" alt="Logo 5" /></div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Clients Section */}

                {/* ======= Features Section ======= */}
                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">

                        <div className="row">
                            <div className="image col-lg-6" style={{ backgroundImage: `url("assets/img/features.jpg")` }} data-aos="fade-right">
                            </div>
                            <div className="col-lg-6" data-aos="fade-left" data-aos-delay="100">
                                <h3>Platform Features</h3>
                                <div className="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
                                    <i className="ri-hospital-fill"></i>
                                    <h4>Hospitals Delivering Better Patient Experience</h4>
                                    <p>Providing health infrastructure access to 60% population.</p>
                                </div>
                                <div className="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
                                    <i className="bi bi-award"></i>
                                    <h4>AI based Operational insights</h4>
                                    <p>Reducing the consulting and treatment cost.</p>
                                </div>
                                <div className="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
                                    <i className="ri-empathize-fill"></i>
                                    <h4>Enabling inclusive healthcare</h4>
                                    <p>Increasing specialist care access to 40% of the underserved population.</p>
                                </div><br />
                                <div className="icon-box mt-5 mt-lg-0" data-aos="zoom-in" data-aos-delay="150">

                                    <i className="ri-mental-health-fill"></i>
                                    <h4>Healthcare provider empowerment.</h4>
                                    <p>Empowering emergency care providers with education and research.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>{/* End Features Section */}

                {/* ======= Services Section ======= */}
                <section id="services" className="services">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Our Solution</h2>
                            <p>Bridging gaps</p>
                        </div>

                        <div className="row">

                            <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
                                <div className="icon-box" style={{ width: '350px' }}>
                                    <div className="icon"><i className="ri-health-book-fill" style={{ color: '#fff' }}></i></div>
                                    <h4><a href="">Wellness Access</a></h4>
                                    <p>A holistic and collaborative approach by OHOINDIA's qualified partners to reduce the patient disease burden and ensure efficient healthcare delivery</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in"
                                data-aos-delay="300">
                                <div className="icon-box" style={{ width: '350px' }}>
                                    <div className="icon"><i className="ri-shake-hands-fill" style={{ color: '#fff' }}></i></div>
                                    <h4><a href="">Networking</a></h4>
                                    <p>Access to our vast healthcare provider database</p>
                                </div>
                            </div>


                            <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                <div className="icon-box" style={{ width: '350px' }}>
                                    <div className="icon"><i className="ri-advertisement-line" style={{ color: '#fff' }}></i></div>
                                    <h4><a href="">Digital Marketing</a></h4>
                                    <p>Increased visibility and higher market positioning within the industry</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in"
                                data-aos-delay="200">
                                <div className="icon-box" style={{ width: '350px' }}>
                                    <div className="icon"><i className="ri-verified-badge-fill" style={{ color: '#fff' }}></i></div>
                                    <h4><a href="">Transportation</a></h4>
                                    <p>Patient transportation at zero cost</p>
                                </div>
                            </div>



                            {/* <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                                <div className="icon-box" style={{ width: '350px' }}>
                                    <div className="icon"><i className='bx bxs-ambulance'></i></div>
                                    <h4><a href="">Emergency Care</a></h4>
                                    <p>Immediate and emergency care for the patients by providing appropriate medical assistance and reducing the transportation time by qualified professionals</p>
                                </div>
                            </div> */}

                            {/* <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                                <div className="icon-box" style={{ width: '350px' }}>
                                    <div className="icon"><i className="ri-presentation-fill"></i></div>
                                    <h4><a href="">Research</a></h4>
                                    <p>Multiple research programs by healthcare industry</p>
                                </div>
                            </div> */}


                        </div>

                    </div>
                </section>{/* End Services Section */}

                {/* ======= Cta Section ======= */}
                <section id="cta" className="cta">
                    <div className="container" data-aos="zoom-in">

                        <div className="text-center">
                            <h3>Do you want know?</h3>
                            <p> How our partners have leveraged the our technology to help solve dynamic healthcare challenges in rural
                                and
                                suburban areas </p>
                            <p>and to discuss how we’ll accelerate this momentum together.</p>
                            <br />
                            <p><i className="ri-phone-fill"></i> Please call us</p>
                            <p><b>+91 - 7671997108</b></p>
                            <p><b>+91 - 7032107108</b></p>
                            <p> <i className="ri-mail-fill"></i> Please write us</p>
                            <p><b>contact@ohoindialife.com</b></p>
                        </div>

                    </div>
                </section>
                {/* End Cta Section */}

                {/* <div className="container" data-aos="fade-up">
                    <p>Our Pricing will start from 500/- only</p>
                </div> */}

                {/* ======= Products Section ======= */}
                <section id="products" className="services">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Our Products</h2>
                            <p>Products we offer</p>
                        </div>

                        <div className="row">

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in"
                                data-aos-delay="300">
                                <div className="icon-box" style={{ width: '350px' }}>
                                    <div className="icon"><i className="bi bi-person" style={{ color: '#fff' }}></i></div>
                                    <h4><a href="">OHO Care</a></h4>
                                    {/* <p className='fs-5' style={{ fontWeight: '500' }}><i className="bi bi-currency-rupee" style={{ color: 'rgb(0,149,182)' }}></i> 149</p> */}
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                <div className="icon-box" style={{ width: '350px' }}>
                                    <div className="icon"><i className="bi bi-person-add" style={{ color: '#fff' }}></i></div>
                                    <h4><a href="">OHO Care Plus</a></h4>
                                    {/* <p className='fs-5' style={{ fontWeight: '500' }}><i className="bi bi-currency-rupee" style={{ color: 'rgb(0,149,182)' }}></i> 1,999</p> */}
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
                                <div className="icon-box" style={{ width: '350px' }}>
                                    <div className="icon"><i className="bi bi-people" style={{ color: '#fff' }}></i></div>
                                    <h4><a href="">OHO Care Family</a></h4>
                                    {/* <p className='fs-5' style={{ fontWeight: '500' }}><i className="bi bi-currency-rupee" style={{ color: 'rgb(0,149,182)' }}></i> 8,999</p> */}
                                </div>
                            </div>

                        </div>

                    </div>
                </section>{/* End Products Section */}

                {/* ======= Counts Section ======= */}
                {/* <section id="counts" className="counts">
                    <div className="container" data-aos="fade-up">

                        <div className="row no-gutters">
                            <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"
                                data-aos="fade-right" data-aos-delay="100">
                                <div>
                                    <img src="./assets/img/image1.jpeg" alt="phoneview_loginPage"
                                        style={{ width: '290px', height: '500px', marginTop: '50px' }} />
                                </div>
                            </div>
                            <div className="col-xl-7 ps-0 ps-lg-5 pe-lg-1 d-flex align-items-stretch" data-aos="fade-left"
                                data-aos-delay="100">
                                <div id="keep-watching-block" style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div className="content d-flex flex-column justify-content-center">
                                        <h3>Working for A BIG GOAL</h3>
                                        <p>
                                            Improving Indian health care access to rural population.
                                        </p>
                                        <div className="row" id="keep-watching" style={{ backgroundColor: '#0094c6', height: '444px', width: '400px', marginLeft: '5px', marginTop: '10px', borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                                            <h4 id="keep-watching-text" style={{ color: '#fff', fontSize: '20px', marginTop: '30px', textAlign: 'center' }} >Keep Continue Watching this Page</h4>
                                            <img src="./assets/img/smilyimage.png" alt="Keep_Watching"
                                                style={{ width: '290px', height: '300px', marginTop: '50px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* End Counts Section */}

                {/* ======= Contact Section ======= */}
                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Contact</h2>
                            <p>Contact Us</p>
                        </div>

                        <div>
                            <iframe style={{ border: 0, width: '100%', height: '270px' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.1960995346017!2d78.38486007390753!3d17.450325800991486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb916176d52e57%3A0xac531bebf89ece0a!2sVision%20Arcade%2C%20Cyber%20Hills%20Colony%2C%20VIP%20Hills%2C%20Silicon%20Valley%2C%20Madhapur%2C%20Hyderabad%2C%20Telangana%20500081!5e0!3m2!1sen!2sin!4v1703683741171!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                        <div className="mt-5">
                            <div>
                                <div className="info row">
                                    <div className='col-12 col-md-6'>
                                        <div className="address">
                                            <i className="bi bi-geo-alt" style={{ color: '#fff' }}></i>
                                            <h4>Location:</h4>
                                            <p>
                                                <b>Office Address:</b> 5th Floor, 1-98/9/4/20, Arunodaya Colony, VIP Hills, Silicon Valley, Madhapur, Hyderabad, Telangana 500081 <br /> <br />
                                                <b>Registered Address:</b> G32, Sripada Nilayam, Tirumala Enclave, Suraram, Hyderabad, Telangana 500055
                                            </p>
                                        </div>
                                    </div>

                                    <div className='col-12 col-md-6'>
                                        <div className="email">
                                            <i className="bi bi-envelope" style={{ color: '#fff' }}></i>
                                            <h4>Email:</h4>
                                            <a style={{ cursor: 'pointer', marginLeft: '17px' }} href="https://www.contact@ohoindialife.com">contact@ohoindialife.com</a>
                                        </div>

                                        <div className="phone">
                                            <i className="bi bi-phone" style={{ color: '#fff' }}></i>
                                            <h4>Call:</h4>
                                            <p><a style={{ cursor: 'pointer' }} href="tel:7671997108">+91 7671997108</a></p>
                                            <p><a style={{ cursor: 'pointer' }} href="tel:7032107108">+91 7032107108</a></p>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            {/* <div className="col-lg-8 mt-5 mt-lg-0">

                                    <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required>
                                            </div>
                                            <div className="col-md-6 form-group mt-3 mt-md-0">
                                                <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required>
                                            </div>
                                        </div>
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required>
                                        </div>
                                        <div className="form-group mt-3">
                                            <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                                        </div>
                                        <div className="my-3">
                                            <div className="loading">Loading</div>
                                            <div className="error-message"></div>
                                            <div className="sent-message">Your message has been sent. Thank you!</div>
                                        </div>
                                        <div className="text-center"><button type="submit">Send Message</button></div>
                                    </form>

                                </div> */}

                        </div>

                    </div>
                </section>{/* End Contact Section */}

            </main>{/* End #main */}

            {/* ======= Footer ======= */}
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-info">
                                    <h4>OHOINDIA LIFE TECH PRIVATE LIMITED<span>
                                        <p>U86909TS2023PTC171688</p>
                                    </span></h4>
                                    <p>
                                        5th Floor, 1-98/9/4/20, Arunodaya Colony, VIP Hills, Silicon Valley, Madhapur, Hyderabad, Telangana 500081 <br /> <br />
                                        <b>Registered Address:</b> G32, Sripada Nilayam, Tirumala Enclave, Suraram, Hyderabad, <br />
                                        Telangana 500055
                                        <br /><br />

                                        <strong>Phone:</strong> <a href="tel:7671997108" style={{ cursor: 'pointer' }}>+91 7671997108</a><br />
                                        <a href="tel:7032107108" style={{ cursor: 'pointer', marginLeft: '50px' }}>+91 7032107108</a><br />
                                        <strong>Email:</strong> <a href="https://www.contact@ohoindialife.com" style={{ cursor: 'pointer' }}>contact@ohoindialife.com</a><br />
                                    </p>
                                    <div className="social-links mt-3">
                                        <a href="https://twitter.com/OhoindiaL" target="_blank" className="twitter">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                            </svg>
                                        </a>
                                        <a href="https://www.facebook.com" target="_blank" className="facebook"><i className="bx bxl-facebook"></i></a>
                                        <a href="https://www.instagram.com/ohoindia_life/" target="_blank" className="instagram"><i className="bx bxl-instagram"></i></a>
                                        {/* <a href="https://www.skype.com" target="_blank" className="google-plus"><i className="bx bxl-skype"></i></a> */}
                                        <a href="https://www.linkedin.com/in/ohoindia-life-tech-private-limited-533527296" target="_blank" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#hero">Home</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#partners">Partners</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href='/termsandconditions' target="_blank">Terms of service</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href='/privacypolicy' target="_blank">Privacy policy</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href='/refundpolicy' target="_blank">Refund policy</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#services">Solution</a></li>
                                    {/* <li><i className="bx bx-chevron-right"></i> <a>Login</a></li> */}
                                    {/* <li><i className="bx bx-chevron-right"></i> <a>Support</a></li> */}
                                    <li><i className="bx bx-chevron-right"></i> <a href="#contact">Contact</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-6 footer-newsletter">
                                <h4>Our Newsletter</h4>
                                <p>Please subscribe to our news letter for insights and latest information.</p>
                                <form action="" method="post">
                                    <input type="email" name="email" /><input type="submit" value="Subscribe" />
                                </form>

                            </div>
                        </div>

                        <div className='mt-3' style={{fontSize: '10px'}}>
                            <p>
                                <b>DISCLAIMER:</b> OHOINDIA LIFE TECH PRIVATE LIMITED is a technology enabled subscription
                                based speciality care service aggregator company registered in India as per companies act.
                                Health pack is offered by Holistic Health Tech Private Limited and Insurance is offered by
                                Svojas Insurance Broking and Risk Management Services Private limited (CIN U67120TG2017PTC118828).
                                IRDAI Broking License Code No. DB 718/17, Certificate No. 627, License category- Direct Broker
                                (Life & General), valid till 09/11/2026.
                            </p>
                            <p>Insurance is the subject matter of the solicitation. Mipinkcover is a combo product, and the product
                                information is solely based on the information received from the insurers. Insurance is offered by
                                Niva Bupa Health Insurance Co. Ltd. For more details on risk factors, associated terms and conditions,
                                and exclusions, please read the sales brochure carefully of the respective insurer before concluding a sale
                            </p>
                        </div>

                    </div>
                </div>

                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>OHOINDIA LIFE TECH PRIVATE LIMITED</span></strong> All Rights Reserved
                    </div>
                </div>
            </footer>{/* End Footer */}<br />
        </div>
    )
};

export default Home;